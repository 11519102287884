export const fields = {
  ap: {
    name: 'ap',
    label: 'Art',
    sort: 1,
    width: 200,
  },
  popNr: {
    name: 'popNr',
    label: 'Pop Nr',
    sort: 2,
    width: 49,
  },
  popName: {
    name: 'popName',
    label: 'Pop Name',
    sort: 3,
    width: 200,
  },
  popStatus: {
    name: 'popStatus',
    label: 'Pop Status',
    sort: 4,
    width: 150,
  },
  nr: {
    name: 'nr',
    label: 'TPop Nr',
    sort: 5,
    width: 58,
  },
  gemeinde: {
    name: 'gemeinde',
    label: 'TPop Gemeinde',
    sort: 6,
    width: 130,
  },
  flurname: {
    name: 'flurname',
    label: 'TPop Flurname',
    sort: 7,
    width: 200,
  },
  status: {
    name: 'status',
    label: 'TPop Status',
    sort: 8,
    width: 150,
  },
  bekanntSeit: {
    name: 'bekanntSeit',
    label: 'TPop bekannt seit',
    sort: 9,
    width: 70,
  },
  lv95X: {
    name: 'lv95X',
    label: 'X Koordinate',
    sort: 10,
    width: 83,
  },
  lv95Y: {
    name: 'lv95Y',
    label: 'Y Koordinate',
    sort: 11,
    width: 83,
  },
  link: {
    name: 'link',
    label: 'TPop Link',
    sort: 12,
    width: 40,
    nofilter: true,
  },
  ekfKontrolleur: {
    name: 'ekfKontrolleur',
    label: 'EKF-KontrolleurIn',
    sort: 13,
    width: 180,
  },
  ekAbrechnungstyp: {
    name: 'ekAbrechnungstyp',
    label: 'EK Abrechnung Typ',
    sort: 14,
    width: 240,
  },
  ekfrequenz: {
    name: 'ekfrequenz',
    label: 'EK Frequenz',
    sort: 15,
    width: 106,
  },
  ekfrequenzStartjahr: {
    name: 'ekfrequenzStartjahr',
    label: 'EK Frequenz Startjahr',
    sort: 16,
    width: 78,
  },
  ekfrequenzAbweichend: {
    name: 'ekfrequenzAbweichend',
    label: 'EK Frequenz abweichend',
    sort: 17,
    width: 85,
  },
  yearTitle: {
    name: 'yearTitle',
    label: 'Ereig- nisse',
    sort: 18,
    width: 50,
    alwaysShow: true,
    nofilter: true,
  },
}

